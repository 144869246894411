import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from "@angular/core"
import { CommonModule, isPlatformBrowser } from "@angular/common"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { CarouselModule, OwlOptions } from "ngx-owl-carousel-o"
import { debounceTime, fromEvent, Subject, takeUntil } from "rxjs"

@Component({
  selector: "app-clients",
  standalone: true,
  imports: [CommonModule, CarouselModule],
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.css"],
})
export class ClientsComponent {
  windowWidth!: number
  private destroy$ = new Subject<void>()
  private isBrowser: boolean

  campingSites = [
    { name: "Chorefto Camp", logo: "./assets/clients/chorefto.png" },
    { name: "Camping Rovies", logo: "./assets/clients/rovies.png" },
    { name: "Camping Thines", logo: "./assets/clients/thines.png" },
    { name: "Lefka Beach Camping", logo: "./assets/clients/lefka.png" },
  ]
  customOptions: OwlOptions = {
    mergeFit: true,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    slideBy: 2,
    navSpeed: 700,
    navText: ["", ""],
    nav: true,
    items: 3,
    margin: 40,
    stagePadding: 1,
    responsive: {
      0: {
        items: 1,
      },
      435: {
        items: 2,
      },
      679: {
        items: 3,
      },
    },
  }

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId)
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.getWindowWidth()
      this.onResize()
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private getWindowWidth() {
    this.windowWidth = window.innerWidth
    console.log("Initial window width:", this.windowWidth)
    this.updateCarouselOptions()
  }

  private onResize() {
    fromEvent(window, "resize")
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        this.windowWidth = window.innerWidth
        console.log("Updated window width:", this.windowWidth)
        this.updateCarouselOptions()
      })
  }

  private updateCarouselOptions() {
    if ((this, this.windowWidth >= 564)) {
      this.customOptions = { ...this.customOptions, slideBy: 2 } // this will make the carousel refresh
    } else if (this.windowWidth < 564) {
      this.customOptions = { ...this.customOptions, slideBy: 1 } // this will make the carousel refresh
    }
  }
}
