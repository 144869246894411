<section class="workflow py-20 bg-gradient-to-b from-petrol to-dark-green">
  <h1
    class="py-2 pl-8 pr-4 bg-white rounded-tr-[9px] rounded-br-[9px] text-dark-green text-section-title w-max"
  >
    Fast, efficient & easy
  </h1>
  <div class="py-[100px] text-center md:py-[144px]">
    <div class="flex items-center justify-center">
      <div class="cloud relative">
        <img
          src="./assets/icons/cloud.svg"
          alt=""
          width="360.41px"
          height="251px"
        />
        <img
          src="./assets/icons/logo.svg"
          alt=""
          class="absolute top-[45px] left-[88px]"
        />
        <p
          class="p-[12.9px] absolute bottom-[11.064px] left-[30.57px] right-[30.57px] text-[25.744px] leading-[36.041px] tracking-[-0.515px]"
        >
          Run your business on a single cloud-based platform
        </p>
      </div>
    </div>
  </div>

  <div
    class="flex flex-wrap items-start justify-center gap-x-[60px] gap-y-12 py-8 px-16"
  >
    <div
      class="w-[204px]"
      *ngFor="let flowItem of flowItems; trackBy: trackByFn"
    >
      <img
        class="mx-auto"
        [src]="flowItem.icon.path"
        [attr.width]="flowItem.icon.width"
        [attr.height]="flowItem.icon.height"
        [attr.alt]="flowItem.icon.alt"
      />
      <p
        class="text-[25.549px] leading-[35.769px] tracking-[-0.511px] text-center p-[12.77px]"
      >
        {{ flowItem.text }}
      </p>
    </div>
  </div>
</section>
