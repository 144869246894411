<section class="pt-14">
  <h1
    class="max-w-max pl-8 pr-4 py-2 mb-[93px] bg-white rounded-tr-[9px] rounded-br-[9px] text-dark-green text-xl font-normal leading-7"
  >
    About Us
  </h1>
  <p class="py-10 px-2 text-center text-[20px] leading-7 tracking-[-0.4px]">
    We are hyped outdoor adventurers and tech lovers. <br />
    Camprrr allows us to combine vacations with creativity and profession.
    <br />
    We are on a mission to bring our favourite type of vacation destinations to
    the digital era.
  </p>
  <div
    class="mt-10 mb-[150px] flex justify-center gap-[50px] min-w-[176px] flex-wrap"
  >
    <div *ngFor="let person of people; trackBy: trackByFn" class="p-2">
      <img
        [ngSrc]="person.image"
        alt=""
        width="160"
        height="160"
        class="mb-[12px]"
      />
      <div class="name text-center mb-3 font-bold">{{ person.name }}</div>
      <div class="caption text-center px-2">{{ person.caption }}</div>
    </div>
  </div>
</section>
