<section class="pt-20 pb-20 bg-dark-green">
  <h1
    class="py-2 pl-8 pr-4 bg-white rounded-tr-[9px] rounded-br-[9px] text-dark-green text-section-title w-max mb-5"
  >
    Trusted by:
  </h1>
  <div class="relative max-w-[679px] mx-14 md:mx-auto py-9">
    <owl-carousel-o [options]="customOptions" #owlCar>
      <ng-container *ngFor="let site of campingSites">
        <ng-template carouselSlide>
          <div class="custom-slide border border-lime-green rounded-3xl p-3">
            <div
              class="flex items-center flex-col gap-[22px] min-h-[255px] bg-gradient-to-b from-white/90 to-[#366] rounded-2xl pt-4"
            >
              <img [src]="site.logo" [alt]="site.name" class="max-w-[156px]" />
              <h4 class="text-center text-dark-green font-bold px-2">
                {{ site.name }}
              </h4>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <button class="custom-nav-btn prev" (click)="owlCar.prev()">
      <svg
        width="29"
        height="49"
        viewBox="0 0 29 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5 45.2891L4.71106 24.5001L25.5 3.71118"
          stroke="white"
          stroke-width="6"
          stroke-linecap="round"
        />
      </svg>
    </button>
    <button class="custom-nav-btn next" (click)="owlCar.next()">
      <svg
        width="29"
        height="49"
        viewBox="0 0 29 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.5 3.71094L24.2889 24.4999L3.5 45.2888"
          stroke="white"
          stroke-width="6"
          stroke-linecap="round"
        />
      </svg>
    </button>
  </div>
</section>
