<nav
  class="flex items-center justify-between text-xs pt-6 px-4 pb-4.5 fixed w-full z-20 transition-[background-color]"
  [ngClass]="transparentNav ? '' : 'bg-dark-green shadow-md'"
>
  <div id="logo" class="font-camprrr text-heading-4">
    camp<span class="text-lime-green">rrr</span>
  </div>
  <button
    class="bg-white border border-white text-dark-green py-[5px] px-5 rounded-xl text-xs font-semibold leading-[16.8px] tracking-[-0.24px]"
    (click)="ngxSmartModalService.getModal('requestDemoForm').open()"
  >
    REQUEST A DEMO
  </button>
</nav>

<ngx-smart-modal #requestDemoForm identifier="requestDemoForm">
  <app-request-form></app-request-form>
</ngx-smart-modal>
