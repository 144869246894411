import { Component, ElementRef, ViewChild } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NgxSmartModalModule, NgxSmartModalService } from "ngx-smart-modal"
import { RequestFormComponent } from "../request-form/request-form.component"
import { LottieComponent, AnimationOptions } from "ngx-lottie"
@Component({
  selector: "app-early-access",
  standalone: true,
  imports: [
    CommonModule,
    NgxSmartModalModule,
    RequestFormComponent,
    LottieComponent,
  ],
  templateUrl: "./early-access.component.html",
  styleUrls: ["./early-access.component.css"],
})
export class EarlyAccessComponent {
  @ViewChild("animation") animation!: ElementRef

  options: AnimationOptions = {
    loop: true,
    autoplay: true,
    path: "assets/animations/psilokatalaves.json",
  }

  constructor(public ngxSmartModalService: NgxSmartModalService) {}
}
