import { Component } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NgOptimizedImage } from "@angular/common"
@Component({
  selector: "app-about-us",
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.css"],
})
export class AboutUsComponent {
  people: Person[] = [
    {
      uuid: "218fca95-d46d-4bbd-a804-1e1c0316526b",
      image: "./assets/photos/khai.webp",
      name: "Khai Dimopoulou",
      caption: "designs it!",
    },
    {
      uuid: "9bb41a73-310e-4936-98fe-630bbfb85432",
      image: "./assets/photos/haskaris.webp",
      name: "Andreas Haskaris",
      caption: "builds it!",
    },
    {
      uuid: "b4b27138-c2ac-482f-9d4f-5146dd8d4fa9",
      image: "./assets/photos/aivaliotis.webp",
      name: "Angelos Aivaliotis",
      caption: "makes it work!",
    },
  ]

  trackByFn(index: number, item: Person) {
    return item.uuid
  }
}

type Person = {
  uuid: string
  image: string
  name: string
  caption: string
}
