<section class="features pt-8 pb-[100px] bg-dark-green overflow-hidden">
  <h1
    class="py-2 pl-8 pr-4 bg-white rounded-tr-[9px] rounded-br-[9px] text-dark-green text-section-title w-max"
  >
    What Camprrr offers:
  </h1>

  <div class="my-28">
    <div class="w-full h-[400px] max-w-3000px min-w-1400px ml-50 translate-50">
      <ng-lottie [options]="options"></ng-lottie>
    </div>
  </div>
  <div class="flex flex-wrap justify-center gap-x-[47px]">
    <div class="w-[316px]">
      <div
        *ngFor="let feature of features1; trackBy: trackByFn"
        class="flex items-center gap-9 mb-9"
      >
        <img
          [src]="feature.icon.path"
          [attr.width]="feature.icon.width"
          [attr.height]="feature.icon.height"
          [attr.alt]="feature.icon.alt"
        />
        <p class="text-feature-item">{{ feature.text }}</p>
      </div>
    </div>
    <div class="w-[316px]">
      <div
        *ngFor="let feature of features2; trackBy: trackByFn"
        class="flex items-center gap-9 mb-9"
      >
        <img
          [src]="feature.icon.path"
          [attr.width]="feature.icon.width"
          [attr.height]="feature.icon.height"
          [attr.alt]="feature.icon.alt"
        />
        <p class="text-feature-item">{{ feature.text }}</p>
      </div>
    </div>
  </div>
</section>
