import { Component } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NavbarComponent } from "./components/navbar/navbar.component"
import { WelcomeComponent } from "./components/welcome/welcome.component"
import { WorkflowComponent } from "./components/workflow/workflow.component"
import { FeaturesComponent } from "./components/features/features.component"
import { PricingComponent } from "./components/pricing/pricing.component"
import { EarlyAccessComponent } from "./components/early-access/early-access.component"
import { AboutUsComponent } from "./components/about-us/about-us.component"
import { FooterComponent } from "./components/footer/footer.component"
import { MyDataComponent } from "./components/my-data/my-data.component"
import { ClientsComponent } from "./components/carousels/owl/clients/clients.component"

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    WelcomeComponent,
    WorkflowComponent,
    FeaturesComponent,
    PricingComponent,
    MyDataComponent,
    EarlyAccessComponent,
    AboutUsComponent,
    FooterComponent,
    ClientsComponent,
  ],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "camprrr-site-2"
}
