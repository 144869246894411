<form
  [formGroup]="requestDemoForm"
  (ngSubmit)="onSubmit()"
  class="flex flex-col gap-4"
>
  <div class="flex flex-col gap-1">
    <label for="name" class="ml-3">Name* </label>
    <input
      id="name"
      type="text"
      formControlName="name"
      class="input"
      placeholder="Jane Doe"
    />
  </div>
  <div class="flex flex-col gap-1">
    <label for="email" class="ml-3">Email* </label>
    <input
      id="email"
      type="text"
      formControlName="email"
      class="input"
      placeholder="myemail@gmail.com"
    />
  </div>
  <div class="flex flex-col gap-1">
    <label for="campingName" class="ml-3">Camping Name </label>
    <input
      id="campingName"
      type="text"
      formControlName="campingName"
      class="input"
      placeholder="Awesome Camp"
    />
  </div>
  <button
    type="submit"
    class="bg-dark-green text-white py-2 px-4 border border-dark-green mt-4 rounded-2xl"
    [disabled]="disableSubmitButton"
  >
    SEND
  </button>
</form>
