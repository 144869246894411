<section class="flex justify-center items-center flex-col gap-[110px] py-12">
  <button
    class="px-[31.82px] py-2 mb-36 bg-lime-green border border-fuchsia-500 text-dark-green text-center text-[25.45px] font-medium leading-9 shadow-[4px_4px_0px_0px_#C22DF6]"
    (click)="ngxSmartModalService.getModal('requestDemoForm').open()"
  >
    Get Early Access!
  </button>
</section>

<ngx-smart-modal #requestDemoForm identifier="requestDemoForm">
  <app-request-form></app-request-form>
</ngx-smart-modal>
