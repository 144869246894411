import { AfterContentInit, Component, OnDestroy, OnInit } from "@angular/core"
import { CommonModule } from "@angular/common"
import {
  FormBuilder,
  ReactiveFormsModule,
  Validators,
  NonNullableFormBuilder,
} from "@angular/forms"
import { ApiService } from "src/app/services/api.service"
import { NotificationService } from "src/app/services/notification/notification.service"
import { NgxSmartModalService } from "ngx-smart-modal"

@Component({
  selector: "app-request-form",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: "./request-form.component.html",
  styleUrls: ["./request-form.component.css"],
})
export class RequestFormComponent implements OnInit, AfterContentInit {
  disableSubmitButton = false

  requestDemoForm = this.nnfb.group({
    name: ["", Validators.required],
    email: ["", Validators.required],
    campingName: [""],
  })

  constructor(
    private nnfb: NonNullableFormBuilder,
    private api: ApiService,
    private notification: NotificationService,
    public ngxSmartModalService: NgxSmartModalService,
  ) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    // this.ngxSmartModalService
    //   .get("requestDemoForm")
    //   .onAnyCloseEventFinished.subscribe(() => {
    //     this.resetForm()
    //   })
  }

  resetForm(): void {
    this.requestDemoForm.reset()
    this.requestDemoForm.controls["name"].setErrors(null)
    this.requestDemoForm.controls["email"].setErrors(null)
  }

  onSubmit() {
    if (this.disableSubmitButton || this.requestDemoForm.invalid) return

    this.disableSubmitButton = true
    this.api.sendEmail(this.requestDemoForm.value).subscribe({
      next: (res: any) => {
        this.disableSubmitButton = false
        this.notification.showSuccess(
          "We received your request and we will contact you shortly!",
          "Success",
        )
        this.ngxSmartModalService.get("requestDemoForm").close()
      },
      error: (err: any) => {
        this.disableSubmitButton = false
        this.notification.showError(
          "Please try again in a few minutes",
          "Something went wrong",
        )
        this.ngxSmartModalService.get("requestDemoForm").close()
      },
      complete: () => {
        console.log("complete")
      },
    })
  }
}
