import { Component } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NgxSmartModalModule, NgxSmartModalService } from "ngx-smart-modal"
import { RequestFormComponent } from "../request-form/request-form.component"

@Component({
  selector: "app-navbar",
  standalone: true,
  imports: [CommonModule, NgxSmartModalModule, RequestFormComponent],
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent {
  transparentNav?: boolean

  constructor(public ngxSmartModalService: NgxSmartModalService) {}
}
