<footer
  class="flex flex-col items-center justify-between gap-7 bg-dark-green px-12 pb-7 sm:flex-row sm:items-end"
>
  <div class="flex gap-10"><a href="">Terms</a><a href="">Privacy</a></div>
  <div class="flex gap-[15px]">
    <a
      href="https://www.linkedin.com/company/85650105/"
      aria-label="Linkedin"
      target="_blank"
    >
      <ng-container [ngTemplateOutlet]="linkedinIcon"></ng-container>
    </a>
    <a
      href="https://www.facebook.com/people/Camprrr/100092775882211/"
      aria-label="Facebook"
      target="_blank"
    >
      <ng-container [ngTemplateOutlet]="facebookIcon"></ng-container>
    </a>
  </div>
</footer>

<ng-template #linkedinIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 37 38"
    fill="none"
  >
    <path
      opacity="0.1"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5 36.5127C28.165 36.5127 36 28.6777 36 19.0127C36 9.34771 28.165 1.5127 18.5 1.5127C8.83502 1.5127 1 9.34771 1 19.0127C1 28.6777 8.83502 36.5127 18.5 36.5127Z"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M14.4835 15.4798C15.3029 15.4798 15.9671 14.8156 15.9671 13.9962C15.9671 13.1769 15.3029 12.5127 14.4835 12.5127C13.6642 12.5127 13 13.1769 13 13.9962C13 14.8156 13.6642 15.4798 14.4835 15.4798Z"
      fill="white"
    />
    <path
      d="M15.7198 16.4707H13.2473C13.1108 16.4707 13 16.5815 13 16.718V24.1357C13 24.2722 13.1108 24.383 13.2473 24.383H15.7198C15.8563 24.383 15.9671 24.2722 15.9671 24.1357V16.718C15.9671 16.5815 15.8563 16.4707 15.7198 16.4707Z"
      fill="white"
    />
    <path
      d="M23.0876 16.0593C22.0308 15.6974 20.7089 16.0153 19.9162 16.5855C19.889 16.4792 19.7921 16.4001 19.6769 16.4001H17.2043C17.0678 16.4001 16.957 16.5108 16.957 16.6473V24.0651C16.957 24.2016 17.0678 24.3123 17.2043 24.3123H19.6769C19.8134 24.3123 19.9241 24.2016 19.9241 24.0651V18.7342C20.3237 18.39 20.8385 18.2802 21.2598 18.4592C21.6683 18.6318 21.9022 19.0531 21.9022 19.6144V24.0651C21.9022 24.2016 22.013 24.3123 22.1495 24.3123H24.622C24.7585 24.3123 24.8693 24.2016 24.8693 24.0651V19.1164C24.8411 17.0845 23.8852 16.3323 23.0876 16.0593Z"
      fill="white"
    />
  </svg>
</ng-template>
<ng-template #facebookIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 37 38"
    fill="none"
  >
    <path
      opacity="0.1"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5 36.5127C28.165 36.5127 36 28.6777 36 19.0127C36 9.34771 28.165 1.5127 18.5 1.5127C8.83502 1.5127 1 9.34771 1 19.0127C1 28.6777 8.83502 36.5127 18.5 36.5127Z"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M20.0932 14.5332H21.7016C21.8495 14.5332 21.9696 14.4201 21.9696 14.2806V12.765C21.9696 12.6256 21.8495 12.5125 21.7016 12.5125H20.0932C18.4677 12.5125 17.1445 13.7588 17.1445 15.291V17.0592H15.2681C15.1201 17.0592 15 17.1724 15 17.3118V18.8274C15 18.9668 15.1201 19.08 15.2681 19.08H17.1445V24.3846C17.1445 24.524 17.2646 24.6372 17.4126 24.6372H19.0209C19.1689 24.6372 19.289 24.524 19.289 24.3846V19.08H21.1655C21.2807 19.08 21.3831 19.0103 21.4201 18.9072L21.9562 17.3916C21.9836 17.3148 21.9696 17.23 21.9192 17.1638C21.8683 17.0981 21.7879 17.0592 21.7016 17.0592H19.289V15.291C19.289 14.8732 19.6498 14.5332 20.0932 14.5332Z"
      fill="white"
    />
  </svg>
</ng-template>
