import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { RequestFormComponent } from '../request-form/request-form.component';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [CommonModule, NgxSmartModalModule, RequestFormComponent],
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent {
  constructor(public ngxSmartModalService: NgxSmartModalService) { }
}
