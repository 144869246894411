<app-navbar></app-navbar>
<app-welcome></app-welcome>
<app-workflow></app-workflow>
<app-features></app-features>
<app-pricing></app-pricing>
<app-early-access></app-early-access>
<app-clients></app-clients>
<app-my-data></app-my-data>
<app-about-us></app-about-us>
<app-footer></app-footer>
