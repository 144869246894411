<section class="pb-[72px]">
  <h1
    class="py-2 pl-8 pr-4 mb-[98px] bg-white rounded-tr-[9px] rounded-br-[9px] text-dark-green text-section-title w-max"
  >
    Pricing
  </h1>
  <div class="flex justify-center flex-wrap gap-[83px]">
    <div
      class="relative pb-[83px] min-w-[296px] w-[296px]"
      *ngFor="let plan of plans; trackBy: trackByFn"
    >
      <div
        class="title z-10 absolute top-[-41px] px-5 py-2 ml-4 bg-lime-green rounded-[22px] text-dark-green text-[32px] font-semibold leading-[44.80px] w-max"
      >
        {{ plan.title }}
      </div>
      <div
        class="content px-4 py-10 bg-[linear-gradient(180deg,rgba(255,255,255,0.62),_#366)] rounded-2xl"
        [ngClass]="plan.title === 'Plus' ? 'blur-sm select-none' : ''"
      >
        <div
          class="text-dark-green font-bold text-[20px] tracking-[-0.4px] mb-2"
        >
          {{ plan.subTitle }}
        </div>
        <ul class="list-disc pl-5">
          <li
            *ngFor="let feature of plan.features"
            class="text-xl font-semibold py-3.5"
          >
            {{ feature }}
          </li>
        </ul>
      </div>
      <div
        class="price mt-2 bg-[linear-gradient(180deg,rgba(182,124,255,0.20)0%,rgba(220,192,255,0.12)41.67%,rgba(255,255,255,0.03)100%)] px-2 py-4 rounded-2xl text-center"
      >
        <h1>
          {{ plan.price !== "Soon..." ? plan.price + "€ / year" : plan.price }}
        </h1>
        <div class="leading-8">
          {{ plan.price !== "Soon..." ? "excl. VAT" : "" }}
        </div>
      </div>
    </div>
  </div>
</section>
