import { Component, ElementRef, OnInit, ViewChild } from "@angular/core"
import { CommonModule } from "@angular/common"
import { AnimationOptions, LottieComponent } from "ngx-lottie"

@Component({
  selector: "app-features",
  standalone: true,
  imports: [CommonModule, LottieComponent],
  templateUrl: "./features.component.html",
  styleUrls: ["./features.component.css"],
})
export class FeaturesComponent implements OnInit {
  @ViewChild("animation") animation!: ElementRef
  features1: Feature[] = [
    {
      uuid: "fea373e5-263f-4089-b89c-0a45ad6fa004",
      icon: {
        path: "./assets/icons/booking-form.png",
        alt: "Online booking form",
        width: "38.253px",
        height: "48px",
      },
      text: "Online booking form",
    },
    {
      uuid: "8c36dd9d-044a-4243-aa70-49c85053642c",
      icon: {
        path: "./assets/icons/gantt-chart-1.png",
        alt: "Powerfull gantt chart",
        width: "49.5px",
        height: "39px",
      },
      text: "Powerfull gantt chart",
    },
    {
      uuid: "74c6b745-80c3-47b1-9173-0786b2a732b5",
      icon: {
        path: "./assets/icons/customer-archive.png",
        alt: "Customer Archive",
        width: "52.305px",
        height: "33px",
      },
      text: "Customer Archive",
    },
  ]

  features2: Feature[] = [
    {
      uuid: "3cdbfd9e-be5e-4164-874f-4762aceceb51",
      icon: {
        path: "./assets/icons/map.png",
        alt: "Map of your campsite",
        width: "50.921px",
        height: "48px",
      },
      text: "Map of your campsite",
    },
    {
      uuid: "1f3ca5fd-061c-424d-b22c-aa3f1c07341a",
      icon: {
        path: "./assets/icons/customization.png",
        alt: "Campsite customization",
        width: "48px",
        height: "48px",
      },
      text: "Campsite customization",
    },
    {
      uuid: "fb5f69c4-e511-417f-a322-9cd8e8e9af79",
      icon: {
        path: "./assets/icons/myData.png",
        alt: "myData",
        width: "48px",
        height: "19.2px",
      },
      text: "Invoicing for Greek campings",
    },
  ]
  options: AnimationOptions = {
    loop: true,
    autoplay: true,
    path: "assets/animations/desk.json",
  }
  ngOnInit(): void {}

  trackByFn(index: number, item: Feature) {
    return item.uuid
  }
}

type Feature = {
  uuid: string
  icon: {
    path: string
    width: string
    height: string
    alt: string
  }
  text: string
}
