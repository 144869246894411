import { ApplicationConfig, importProvidersFrom } from "@angular/core"
import { NgxSmartModalModule } from "ngx-smart-modal"
import { provideHttpClient } from "@angular/common/http"
import { provideAnimations } from "@angular/platform-browser/animations"

import { provideToastr } from "ngx-toastr"
import { NotificationComponent } from "./components/notification/notification.component"
import { provideLottieOptions } from "ngx-lottie"

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(NgxSmartModalModule.forRoot()),
    provideHttpClient(),
    provideAnimations(), // required animations providers
    provideToastr({
      toastComponent: NotificationComponent,
      closeButton: true,
      toastClass: "",
      titleClass: "",
      messageClass: "",
      preventDuplicates: true,
      tapToDismiss: true,
    }),
    provideLottieOptions({
      player: () => import("lottie-web"),
    }),
  ],
}
