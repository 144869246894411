import { Component } from "@angular/core"
import { CommonModule } from "@angular/common"
import { NgOptimizedImage } from "@angular/common"

@Component({
  selector: "app-workflow",
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: "./workflow.component.html",
  styleUrls: ["./workflow.component.css"],
})
export class WorkflowComponent {
  flowItems: FlowItem[] = [
    {
      uuid: "be893359-afa5-4ca0-84bc-6748e566947c",
      icon: {
        path: "./assets/icons/flow-asset-1.svg",
        height: "84.632px",
        width: "77.04px",
        alt: "",
      },
      text: "Receive booking requests",
    },
    {
      uuid: "8101472b-5c48-41ec-a83a-bb383d93c115",
      icon: {
        path: "./assets/icons/flow-asset-2.svg",
        height: "84.632px",
        width: "77.04px",
        alt: "Receive booking requests",
      },
      text: "Keep track of the reservation during the stay",
    },
    {
      uuid: "205b638e-2700-4ba9-abb6-cde6482d6856",
      icon: {
        path: "./assets/icons/flow-asset-3.svg",
        height: "84.632px",
        width: "84.632px",
        alt: "Keep track of the reservation during the stay",
      },
      text: "Checkout with just a few clicks",
    },
    {
      uuid: "2b4f416f-65a6-4c7a-82a4-a2691f05809a",
      icon: {
        path: "./assets/icons/flow-asset-4.svg",
        height: "84.632px",
        width: "97.834px",
        alt: "Get live reports and monitor your camp at any time",
      },
      text: "Get live reports and monitor your camp at any time",
    },
  ]

  trackByFn(index: number, item: FlowItem) {
    return item.uuid
  }
}

type FlowItem = {
  uuid: string
  icon: {
    path: string
    width: string
    height: string
    alt: string
  }
  text: string
}
