<section
  class="welcome-section px-2 text-center pt-[69px] h-screen flex flex-col justify-around items-center bg-[url('/assets/images/hero.webp')] bg-cover bg-no-repeat bg-center sm:px-4"
>
  <h1 class="text-center font-bold py-2 text-[40px]">
    Camping management, fun & simple. <br />
    Just like vacations
  </h1>
  <p class="py-2 text-[24px] leading-[33.6px] tracking-[-0.48px]">
    Camping Management Software
  </p>
  <button
    class="bg-white border border-white text-dark-green py-[5px] px-5 rounded-xl text-xs font-semibold leading-[16.8px] tracking-[-0.24px] md:py-[10] md:px-[40px] md:text-2xl md:leading-[33.6px] md:tracking-[-0.48px]"
    (click)="ngxSmartModalService.getModal('requestDemoForm').open()"
  >
    REQUEST A DEMO
  </button>
</section>

<ngx-smart-modal #requestDemoForm identifier="requestDemoForm">
  <app-request-form></app-request-form>
</ngx-smart-modal>
