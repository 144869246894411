import { Component } from "@angular/core"
import { CommonModule } from "@angular/common"

@Component({
  selector: "app-pricing",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.css"],
})
export class PricingComponent {
  plans: Plan[] = [
    {
      uuid: "a147f023-dca9-4acf-8de2-e74c248e0280",
      title: "Mini",
      features: [
        "Reservation Management",
        "Timeline View",
        "Spots Plan",
        "Combos",
        "Reports",
      ],
      price: "800",
    },
    {
      uuid: "d16ca966-8aff-459e-8c75-4cd6e870c05d",
      title: "Standard",
      subTitle: "Everything included in Mini, plus...",
      features: [
        "Booking Form",
        "Advanced Reports",
        "Campsite customization",
        "Welcome message for your check-ins",
        "Customer Archive",
        "Map of your campsite",
      ],
      price: "1500",
    },
  ]

  trackByFn(index: number, item: Plan) {
    return item.uuid
  }
}

type Plan = {
  uuid: string
  title: string
  subTitle?: string
  features: string[]
  price: string
}
